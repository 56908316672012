.Olympics {
    text-align: center;
    font-family: Poppins, 'Nanum Gothic', sans-serif;
    box-sizing: border-box;
    line-height: 1.5;
    height: 100%;
    margin: 0;
    padding: 0;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    font-size: 12px !important;
    display: flex;
    flex: 1;
    flex-direction: column;
    color: #3F4254;
    /*background: #EEF0F8;*/
}

.Olympics h1,
.Olympics h2,
.Olympics h3,
.Olympics h4,
.Olympics h5,
.Olympics h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.olympics-beta-version {
    display: none;
}