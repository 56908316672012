body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans KR', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --olympic-red: #ef476fff;
  --olympic-danger: #ef476fff;
  --olympic-yellow: #ffd166ff;
  --olympic-warning: #ffd166ff;
  --olympic-green: #06d6a0ff;
  --olympic-success: #06d6a0ff;
  --olympic-blue: #118ab2ff;
  --olympic-info: #118ab2ff;
  --olympic-black: #073b4cff;
  --olympic-primary: #073b4cff;
}
